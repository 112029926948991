var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"production@1"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing'; // Must import second
import { version } from '../package.json';

const ENV = process.env.NODE_CONFIG_ENV;
const DSN = process.env.SENTRY_DSN;

Sentry.init({
  dsn: DSN,
  tracesSampleRate: 1.0,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'module-viewer-web-app', 'learner-rest-api']
    })
  ],
  release: ENV + '@' + version,
  environment: ENV
});

console.log(process.env);
